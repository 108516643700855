import { initializeFrontendLogtailLogger } from "@utils/logtail"

let logtail: ReturnType<typeof initializeFrontendLogtailLogger> | null = null

export const logOnlyInDevelopment = (logPrefix: string, message: string, logLevel: string) => {
  // Enable it in development and disable it before committing to prevent multiple logs from different developers
  const disableLogging = true
  if (disableLogging) {
    return
  }
  // Prevent logging in production
  if (process.env.NODE_ENV === "production") {
    return
  }
  // lazy initialize the logtail instance
  if (!logtail) {
    logtail = initializeFrontendLogtailLogger()
  }
  const level = logLevel || "log"
  const prefixedMessage = `${logPrefix}${message}`
  switch (level) {
    case "info":
      logtail.info(prefixedMessage)
      break
    case "warn":
      logtail.warn(prefixedMessage)
      break
    case "debug":
      logtail.debug(prefixedMessage)
      break
    default:
      logtail.log(prefixedMessage)
  }
}
