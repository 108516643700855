import React, { useState, PropsWithChildren } from "react"
import {
  type HuudleSummaryType,
  type ContributionSummaryType,
  type AINotesType,
  ContributionErrorEnum,
  HuudleErrorEnum
} from "./index.types"
import { useSession } from "@supabase/auth-helpers-react"
import { useHuudleDetail } from "@contexts/huudledetail"
import { useCheckFeatureLicense } from "@hooks/use-check-feature-license"
import { useProfile } from "@contexts/profile/hook"
import { PostType } from "../../../pages/api/entity/HuudleDetailType"
import { PostWithProgressType } from "@contexts/huudledetail/index.types"

export const AiNotesContext = React.createContext<AINotesType>({
  huudle: null,
  setHuudle: () => null,
  contribution: null,
  setContribution: () => null,
  contributionError: "",
  contributionLoading: false,
  huudleError: "",
  huudleLoading: false,
  createSummary: async () => undefined
  // huudleSentimentalError: "",
  // huudleSentimentalLoading: false
})

export const AiNotesContextProvider = ({ children }: PropsWithChildren) => {
  const [huudle, setHuudle] = useState<HuudleSummaryType | null>(null)
  // const [huudleSentimentalLoading, setHuudleSentimentalLoading] = useState<boolean>(false)
  // const [huudleSentimentalError, setHuudleSentimentalError] = useState<string | null>(null)
  const [contribution, setContribution] = useState<ContributionSummaryType | null>(null)
  const [contributionError, setContributionError] = useState<string | null>(null)
  const [contributionLoading, setContributionLoading] = useState<boolean>(false)
  const [huudleError, setHuudleError] = useState<string | null>(null)
  const [huudleLoading, setHuudleLoading] = useState<boolean>(false)
  const session = useSession()
  const { profile } = useProfile()
  const isAnonUser = profile?.isAnon
  const { details, currentMedia } = useHuudleDetail()
  const { isValid: isValidLicenseAI } = useCheckFeatureLicense("ai_notes")

  const fetchContributionAI = async () => {
    try {
      if (!details?.huudle.id || !currentMedia?.media?.post_order) return
      if (
        currentMedia.media.post_order.toString() === contribution?.post_id &&
        contribution.summary &&
        contribution.subtitle
      )
        return
      setContributionLoading(true)
      setContributionError("")
      setContribution(null)
      const url = `/api/ai/contributions/get-transcript?group_id=${details?.huudle.id}&post_id=${currentMedia?.media?.post_order}&user_id=${session?.user.id}`
      const res = await fetch(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${session?.access_token}`
        }
      })
      const data = await res.json()
      if (res.status === 404) {
        setContribution(null)
        setContributionLoading(false)
        setContributionError(ContributionErrorEnum.NOT_FOUND)
      }

      // if (res.status !== 404 && data.status !== "done") {
      //   setContributionError(ContributionErrorEnum.NOT_READY)
      //   return setTimeout(() => {
      //     setRefetch(!refetch)
      //   }, 2000)
      // }
      if (data.summary) {
        setContribution(data)
        setContributionLoading(false)
        setContributionError("")
        // if (!data?.highlight_url || !data?.sentimental_analysis) {
        //   return setTimeout(() => {
        //     reFetchContribution()
        //   }, 3000)
        // }
        return
      }
    } catch (err) {
      setContribution(null)
      setContributionLoading(false)
      setContributionError(JSON.stringify(err) || ContributionErrorEnum.FAILED)
    }
  }

  // const [refetch, setRefetch] = React.useState(false)

  React.useEffect(() => {
    if (
      !details?.huudle.id ||
      !currentMedia?.media?.post_order ||
      !isValidLicenseAI ||
      isAnonUser
    ) {
      setHuudleLoading(false)
      return
    }
    fetchContributionAI()
  }, [currentMedia?.media?.post_order, isValidLicenseAI, details?.huudle.id, isAnonUser])

  // const reFetchGroup = async () => {
  //   try {
  //     const url = `/api/ai/huudles/get-summary?group_id=${details?.huudle.id}`
  //     const res = await fetch(url, {
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${session?.access_token}`
  //       }
  //     })
  //     const data = await res.json()

  //     if (data?.sentimental_analysis) {
  //       setHuudleSentimentalLoading(false)
  //       setHuudleSentimentalError("")
  //       setHuudle(prev => ({ ...prev!, sentimental_analysis: data?.sentimental_analysis }))
  //       return
  //     }
  //     if (data?.extended_analysis) {
  //       setHuudle(prev => ({ ...prev!, extended_analysis: data?.extended_analysis }))
  //     }

  //     if (res.ok && (!data?.sentimental_analysis || !data.extended_analysis)) {
  //       return setTimeout(() => {
  //         reFetchGroup()
  //       }, 3000)
  //     }

  //     if (res.status === 404) {
  //       setHuudleLoading(false)
  //       setHuudleError(HuudleErrorEnum.NOT_FOUND)
  //       return
  //     }

  //     if (!res.ok) {
  //       setHuudleLoading(false)
  //       setHuudleError(HuudleErrorEnum.FAILED)
  //       return
  //     }
  //   } catch (err) {
  //     setHuudleSentimentalLoading(false)
  //     setHuudleSentimentalError(HuudleErrorEnum.FAILED)
  //     return
  //   }
  // }

  // const reFetchContribution = async () => {
  //   try {
  //     const url = `/api/ai/contributions/get-transcript?group_id=${details?.huudle.id}&post_id=${currentMedia?.media?.post_order}`
  //     const res = await fetch(url, {
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${session?.access_token}`
  //       }
  //     })
  //     const data = await res.json()
  //     if (data?.highlight_url && !contribution?.highlight_url) {
  //       setContribution(prev => {
  //         if (!prev) return null
  //         return {
  //           ...prev,
  //           highlight_url: data.highlight_url
  //         }
  //       })
  //     }
  //     if (data?.sentimental_analysis && !contribution?.sentimental_analysis) {
  //       setContribution(prev => {
  //         if (!prev) return null
  //         return {
  //           ...prev,
  //           sentimental_analysis: data.sentimental_analysis
  //         }
  //       })
  //     }

  //     if (!data.sentimental_analysis || !data.highlight_url) {
  //       setTimeout(() => {
  //         reFetchContribution()
  //       }, 3000)
  //     }
  //   } catch (err) {
  //     setContributionLoading(false)
  //     setContributionError(JSON.stringify(err) || ContributionErrorEnum.FAILED)
  //   }
  // }

  const fetchGroupSummary = async () => {
    try {
      setHuudleLoading(true)
      if (details?.posts?.length === 0 || details?.posts === null) {
        setHuudleLoading(false)
        setHuudleError(HuudleErrorEnum.NO_POST)
        return
      }
      const url = `/api/ai/huudles/get-summary?group_id=${details?.huudle.id}&user_id=${session?.user.id}`
      const res = await fetch(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${session?.access_token}`
        }
      })
      const data = await res.json()
      if (res.ok && data && data.status !== "done") {
        // if (!contribution?.summary) setRefetch(!refetch)
        return setTimeout(() => {
          fetchGroupSummary()
          // if (!contribution?.summary) setRefetch(!refetch)
        }, 3000)
      }

      if (data?.summary && data.status === "done") {
        setHuudle(data)
        setHuudleLoading(false)
        setHuudleError(null)
        // if (!data?.sentimental_analysis || !data?.extended_analysis) {
        //   setHuudleSentimentalLoading(true)
        //   return setTimeout(() => {
        //     reFetchGroup()
        //   }, 3000)
        // }
      }
      if (res.status === 404) {
        setHuudleLoading(false)
        setHuudleError(HuudleErrorEnum.NOT_FOUND)
        return
      }
      if (!res.ok) {
        setHuudleLoading(false)
        setHuudleError(HuudleErrorEnum.FAILED)
        return
      }
    } catch (err) {
      setHuudleLoading(false)
      setHuudleError(HuudleErrorEnum.FAILED)
      return
    }
  }

  const createSummary = async (allPosts: PostType[] | PostWithProgressType[] | undefined) => {
    if (!allPosts) return
    if (isAnonUser) return
    setHuudleLoading(true)
    setHuudleError("")
    setContributionLoading(true)
    setContributionError("")
    const posts = allPosts?.map(item => {
      return {
        group_id: details?.huudle.id.toString(),
        post_type: item.post_type === "Comment" ? "Text" : item.post_type,
        post_id: item.post_order,
        ...(item.post_type === "Comment" ? { transcript: item.content } : {}),
        ...(item.post_type !== "Comment"
          ? {
              url: item?.content
                .replace("http://localhost:5030", "https://huudle.s3.us-east-1.amazonaws.com")
                .replace("https://media.huudle.io", "https://huudle.s3.us-east-1.amazonaws.com")
                .replace(
                  "https://huudle.s3-accelerate.amazonaws.com",
                  "https://huudle.s3.us-east-1.amazonaws.com"
                )
                .replace(
                  "https://huudle.s3.amazonaws.com/",
                  "https://huudle.s3.us-east-1.amazonaws.com/"
                )
            }
          : {}),
        prompts: [
          {
            key: "Speaker Name",
            value: item.owner_name
          },
          {
            key: "Meeting Subject",
            value: details?.huudle?.subject
          }
        ]
      }
    })
    if (!posts) {
      setHuudleError(HuudleErrorEnum.NO_POST)
      return []
    }

    const body = {
      group_id: details?.huudle?.id.toString(),
      posts: posts,
      user_id: session?.user.id
    }

    await fetch("/api/ai/huudles/create-summary", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${session?.access_token}`
      },
      body: JSON.stringify(body)
    })
    return fetchGroupSummary()
  }

  React.useEffect(() => {
    if (!details?.huudle.id || !isValidLicenseAI || isAnonUser) {
      setHuudleLoading(false)
      return
    }
    setHuudle(null)
    fetchGroupSummary()
  }, [details?.huudle.id, isValidLicenseAI, isAnonUser])

  return (
    <AiNotesContext.Provider
      value={{
        huudle,
        setHuudle,
        contribution,
        setContribution,
        huudleError,
        huudleLoading,
        contributionError,
        contributionLoading,
        createSummary
        // huudleSentimentalLoading,
        // huudleSentimentalError
      }}
    >
      {children}
    </AiNotesContext.Provider>
  )
}

export const useAINotes = () => React.useContext(AiNotesContext)
