import { paddingString } from "@utils/padding-string"

export const convertSecondsToHMS = (totalSeconds: number) => {
  const hours = Math.floor(totalSeconds / 3600)
  const minutes = Math.floor((totalSeconds % 3600) / 60)
  const seconds = totalSeconds % 60

  return { hours, minutes, seconds }
}

export const convertRemainingSecondsToHMS = (seconds: number, maxSeconds: number) => {
  return convertSecondsToHMS(calculateRemainingSeconds(seconds, maxSeconds))
}

export const convertRemainingSecondsToTextWithMaxSeconds = (
  seconds: number,
  maxSeconds: number
) => {
  return convertSecondsToTextWithMaxSeconds(
    calculateRemainingSeconds(seconds, maxSeconds),
    maxSeconds
  )
}

export const calculateRemainingSeconds = (seconds: number, maxSeconds: number) => {
  return maxSeconds ? maxSeconds - seconds : seconds
}

const convertSecondsToTextWithMaxSeconds = (seconds: number, maxSeconds: number) => {
  const hms = convertSecondsToHMS(seconds)
  const hmsText = `${hms.hours > 0 ? `${paddingString(hms.hours, 2, "0")}:` : ""}${paddingString(
    hms.minutes,
    2,
    "0"
  )}:${paddingString(hms.seconds, 2, "0")}`

  const maxAvailable = !!maxSeconds
  const maxHms = maxAvailable && convertSecondsToHMS(maxSeconds)
  const maxHmsText =
    maxHms &&
    `${maxHms.hours > 0 ? `${paddingString(maxHms.hours, 2, "0")}:` : ""}${paddingString(
      maxHms.minutes,
      2,
      "0"
    )}:${paddingString(maxHms.seconds, 2, "0")}`

  return maxAvailable ? `${hmsText} / ${maxHmsText}` : hmsText
}
