/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react"

type QueryFn<TData = any, TVariables extends any[] = any[]> = (
  ...args: TVariables
) => Promise<TData>

type Options<TData = any, TVariables extends any[] = any[], TError = any> = {
  key?: string
  lazy?: boolean
  keepPreviousData?: boolean
  onSuccess?: (data: TData) => void
  onError?: (error: TError) => void
  variables?: TVariables extends undefined ? never : TVariables
}

type Result<TData = any, TError = any> = {
  data: TData | null
  error?: TError | null
  isLoading: boolean
  run: () => void
}

const store = new Map<string, any>()

/**
 * This hook is used to fetch data from an API. It returns the data, error, isLoading and run function.
 * @param queryFn Query function that returns a promise with the data
 * @param options Options for the hook+
 * @returns The data, error, isLoading and run function
 */
export const useQuerify = <TData = any, TVariables extends any[] = any[], TError = any>(
  queryFn: QueryFn<TData, TVariables>,
  options: Options<TData, TVariables, TError> = {}
): Result<TData, TError> => {
  // Destructure the options
  const { key, variables = [], lazy = false, keepPreviousData = true, onError, onSuccess } = options
  // The `data` state, if key is provided, checks the store for the initial data
  const [data, setData] = React.useState<TData | null>(() => {
    if (key) {
      return store.get(`${key}-${JSON.stringify(variables)}`)
    }
    return null
  })
  // The `error` state
  const [error, setError] = React.useState<TError | null>(null)
  // The `isLoading` state
  const [isLoading, setIsLoading] = React.useState(false)

  /**
   * This refs are used to keep the `onSuccess` and `onError` functions updated
   * But without causing a request loop
   */
  const onSuccessRef = React.useRef(onSuccess)
  const onErrorRef = React.useRef(onError)

  React.useEffect(() => {
    onSuccessRef.current = onSuccess
  }, [onSuccess])

  React.useEffect(() => {
    onErrorRef.current = onError
  }, [onError])

  /**
   * Similar to the `onSuccess` and `onError` refs, this state is used to keep the `variables` updated
   * But without causing a request loop
   */
  const [variablesMemoized, setVariablesMemoized] = React.useState(variables)

  React.useEffect(() => {
    if (JSON.stringify(variablesMemoized) !== JSON.stringify(variables)) {
      setVariablesMemoized(variables)
    }
  }, [variables, variablesMemoized])

  /**
   * The main query runner function
   * Sets the loading, clears the error.
   * Sends the request and sets the data or error.
   * Finally, sets the loading to false.
   *
   * Also, if the `key` is provided, it stores the data in the store.
   */
  const run = React.useCallback(() => {
    setIsLoading(true)
    if (!keepPreviousData) {
      setData(null)
    }
    setError(null)
    queryFn(...(variablesMemoized as TVariables))
      .then(data => {
        setData(data)
        onSuccessRef.current?.(data)
        if (key) {
          store.set(`${key}-${JSON.stringify(variablesMemoized)}`, data)
        }
      })
      .catch(error => {
        setError(error)
        onErrorRef.current?.(error)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [keepPreviousData, key, queryFn, variablesMemoized])

  /**
   * If the `lazy` option is set to false, it runs the query on mount.
   */
  React.useEffect(() => {
    if (!lazy) {
      run()
    }
  }, [run, lazy])

  return {
    data,
    error,
    isLoading,
    run
  }
}
