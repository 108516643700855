import * as React from "react"
import { type AppProps } from "next/app"
import { SessionContextProvider, type Session } from "@supabase/auth-helpers-react"
import dynamic from "next/dynamic"
import Head from "next/head"

import "react-datepicker/dist/react-datepicker.css"
import "swiper/scss"
import "react-multi-email/dist/style.css"
import "@styles/globals.scss"
import "@styles/overrides.scss"

import { ProfileContextProvider } from "@contexts/profile"
import { HuudleDetailProvider } from "@contexts/huudledetail"
import { DashboardContextProvider } from "@contexts/dashboard"
import { TimelineProvider } from "@contexts/timeline"
import { PopupProvider } from "@contexts/popup"
import { supabasePublicClient } from "@lib/supabase"
import { fontClassName } from "@lib/font"
import { LoadingProvider } from "@contexts/loading"
import { AiNotesContextProvider } from "@contexts/ai-notes"
import { GoogleOAuthProvider } from "@react-oauth/google"

const ToastContainerDynamic = dynamic(
  () => import("react-toastify").then(file => file.ToastContainer),
  { ssr: false }
)

const App = ({
  Component,
  pageProps
}: AppProps<{
  initialSession: Session
}>) => {
  let clientId

  if (process.env.NEXT_PUBLIC_ENV !== "TEST") {
    clientId = "593026918415-kd2qvlo5rspf52r6bsnna9unssid63d6.apps.googleusercontent.com"
  } else {
    clientId = "593026918415-cepifec82i990lcmksduvalvqg6tffqh.apps.googleusercontent.com"
  }

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
        />
      </Head>
      <main className={fontClassName}>
        <SessionContextProvider
          supabaseClient={supabasePublicClient}
          initialSession={pageProps.initialSession}
        >
          <LoadingProvider>
            <ToastContainerDynamic newestOnTop />
            <ProfileContextProvider>
              <GoogleOAuthProvider clientId={clientId}>
                <DashboardContextProvider>
                  <HuudleDetailProvider>
                    <AiNotesContextProvider>
                      <TimelineProvider>
                        <PopupProvider>
                          <Component {...pageProps} />
                        </PopupProvider>
                      </TimelineProvider>
                    </AiNotesContextProvider>
                  </HuudleDetailProvider>
                </DashboardContextProvider>
              </GoogleOAuthProvider>
            </ProfileContextProvider>
          </LoadingProvider>
        </SessionContextProvider>
      </main>
    </>
  )
}
export default App
