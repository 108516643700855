export const PORTAL_ID = "__portal"
export const PORTAL_SELECTOR = `#${PORTAL_ID}`
export const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec"
]
// Can be accessed only by frontend
export const APP_URL = process.env.NEXT_PUBLIC_APP_URL
// Can be accessed only by frontend
export const SUPABASE_URL: string = process.env.NEXT_PUBLIC_SUPABASE_URL as string
// Can be accessed only by frontend
export const SUPABASE_ANON_KEY: string = process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY as string
// Can be accessed only by backend
export const SUPABASE_SERVICE_ROLE_KEY: string = process.env.SUPABASE_SERVICE_ROLE_KEY as string
// Can be accessed only by backend
export const ASANA_CLIENT_ID = "1207393398296469"
// Can be accessed only by backend
export const ASANA_CLIENT_SECRET = process.env.ASANA_CLIENT_SECRET as string
// Can be accessed only by backend
export const SUPABASE_AI_URL = process.env.SUPABASE_AI_URL as string
// Can be accessed only by backend
export const SUPABASE_AI_SERVICE_ROLE_KEY = process.env.SUPABASE_AI_SERVICE_ROLE_KEY as string
