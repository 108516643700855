import { createClient } from "@supabase/supabase-js"
import { NextApiRequest } from "next"
import { Database } from "@lib/supabase-types"
import { SUPABASE_ANON_KEY, SUPABASE_URL } from "./constants"

export const createSupabaseClientWithUrlAndKey = (url: string, key: string) => {
  const supabase = createClient<Database>(url, key)
  return supabase
}

export const supabasePublicClient = createClient<Database>(SUPABASE_URL, SUPABASE_ANON_KEY)

export const supabaseClientByAuthorizationHeader = async (req: NextApiRequest) => {
  const authHeader = req.headers["authorization"]
  if (!authHeader) {
    return null
  }
  const supabase = createClient<Database>(SUPABASE_URL, SUPABASE_ANON_KEY, {
    global: { headers: { Authorization: authHeader ?? "" } }
  })
  return supabase
}
