import { APP_URL } from "@lib/constants"
import { Session } from "@supabase/supabase-js"

const getLicense = async (session: Session) => {
  const userAccessToken = session?.access_token

  const license = await fetch(`${APP_URL}/api/payment/license-management/get-user-license`, {
    method: "POST",

    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userAccessToken}`
    },
    body: JSON.stringify({
      user_uuid: session?.user.id
    })
  })

  return license
}

export default getLicense
