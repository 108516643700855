import React, { FC, PropsWithChildren } from "react"
import { IDialogContext, TOnButtonClick } from "./interface"

export const PopupContext = React.createContext<IDialogContext>({
  message: "",
  onClose: () => undefined,
  open: false,
  id: "",
  onButtonClick: () => undefined,
  primaryButtonText: "",
  secondaryButtonText: "",
  setPrimaryButtonText: () => undefined,
  setSecondaryButtonText: () => undefined,
  setMessage: () => undefined,
  setId: () => undefined,
  setTitle: () => undefined,
  title: "",
  toggleVisibility: () => undefined,
  setOnButtonClick: () => undefined
})

export const PopupProvider: FC<PropsWithChildren> = ({ children }) => {
  const [message, setMessage] = React.useState("")
  const [id, setId] = React.useState("")
  const [title, setTitle] = React.useState("")
  const [primaryButtonText, setPrimaryButtonText] = React.useState("")
  const [secondaryButtonText, setSecondaryButtonText] = React.useState("")
  const [open, toogleVisibility] = React.useState(false)
  const [onButtonClick, setOnButtonClick] = React.useState<TOnButtonClick>(() => () => undefined)

  return (
    <PopupContext.Provider
      value={{
        message,
        setMessage,
        id,
        setId,
        title,
        setTitle,
        primaryButtonText,
        setPrimaryButtonText,
        secondaryButtonText,
        setSecondaryButtonText,
        onClose: () => undefined,
        toggleVisibility: toogleVisibility,
        onButtonClick,
        setOnButtonClick,
        open
      }}
    >
      {children}
    </PopupContext.Provider>
  )
}
