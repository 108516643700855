import { HttpCookie } from "@lib/types"

const getCookieByNameFromDocument = (name: string): HttpCookie | undefined => {
  const allCookies = getAllCookiesFromDocument()
  return allCookies.find(cookie => cookie.name === name)
}

const getAllCookiesFromDocument = (): HttpCookie[] => {
  return parseCookieString(document.cookie)
}

const parseCookieString = (cookieString: string): HttpCookie[] => {
  const cookieData = cookieString.split("; ")

  return cookieData.map(pair => {
    const [name, value] = pair.split("=")
    return { name: decodeURIComponent(name || ""), value: decodeURIComponent(value || "") }
  })
}

export { getCookieByNameFromDocument }
