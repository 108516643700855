/* eslint-disable no-console */
import React, { PropsWithChildren, useReducer, useState } from "react"
import { useRouter } from "next/router"
import { HuudleListItemType } from "@contexts/dashboard/index.types"
import { GetUserLicenseResponse } from "@lib/psp/license_management"
import { useSession, useSessionContext } from "@supabase/auth-helpers-react"
import getLicense from "@utils/get-license"
import { getCookieByNameFromDocument } from "@utils/http-cookie"
import {
  Action,
  DashboardType,
  PurchaseModalAction,
  PurchaseModalState,
  SortType,
  SubjectState,
  UserInfoAction,
  UserInfoState
} from "./index.types"
import { supabasePublicClient } from "@lib/supabase"
import { APP_URL } from "@lib/constants"
import { logOnlyInDevelopment } from "@lib/logging/util"
import { initializeFrontendLogtailLogger } from "@utils/logtail"

const logPrefix = "aws/contexts/dashboard/index.tsx - "
const logtail = initializeFrontendLogtailLogger()

const initialState: SubjectState = {
  showCreateAccountModal: false,
  accountInfo: {
    name: ""
  },
  selectedEvents: [],
  attendees: []
}

const initialStatePurchase: PurchaseModalState = {
  showPurchaseModal: false,
  message: ""
}

const initialStateUserInfo: UserInfoState = {
  role: 0,
  purpose: 0
}

export const DashboardContext = React.createContext<DashboardType>({
  sort: undefined,
  setSort: () => null,
  handleAddFilter: () => null,
  handleRemoveFilter: () => null,
  reloadFetchHuudle: false,
  setReloadFetchHuudle: () => null,
  state: initialState,
  dispatch: () => null,
  huudleData: null,
  setHuudleData: () => null,
  dashboardLoading: true,
  setDashboardLoading: () => null,
  license: undefined,
  setLicense: () => null,
  statePurchase: initialStatePurchase,
  dispatchPurchase: () => null,
  stateUserInfo: initialStateUserInfo,
  dispatchUserInfo: () => null,
  calendarConnected: undefined,
  setCalendarConnected: () => null,
  calendarConnectionLoading: false,
  setCalendarConnectionLoading: () => null,
  showDisconnectCalendarModal: false,
  setShowDisconnectCalendarModal: () => null,
  isFetching: false,
  setIsFetching: () => null,
  page: undefined,
  setPage: () => null,
  totalHuudleCount: undefined,
  setTotalHuudleCount: () => null,
  showSubscribeModal: false,
  setShowSubscribeModal: () => null
})

export const DashboardContextProvider = ({ children }: PropsWithChildren) => {
  const [sort, setSort] = useState<SortType[]>()
  const [reloadFetchHuudle, setReloadFetchHuudle] = useState(false)
  const [huudleData, setHuudleData] = React.useState<HuudleListItemType[] | null>(null)
  const [dashboardLoading, setDashboardLoading] = React.useState(true)
  const [license, setLicense] = React.useState<{ licenseStatus: GetUserLicenseResponse[] }>()
  const [calendarConnected, setCalendarConnected] = React.useState<boolean>()
  const [calendarConnectionLoading, setCalendarConnectionLoading] = React.useState(false)
  const [showDisconnectCalendarModal, setShowDisconnectCalendarModal] = React.useState(false)
  const [isFetching, setIsFetching] = React.useState<boolean>(false)
  const [page, setPage] = React.useState<number | undefined>()
  const [totalHuudleCount, setTotalHuudleCount] = React.useState<number | undefined>()
  const [showSubscribeModal, setShowSubscribeModal] = React.useState(false)

  const router = useRouter()
  const { query, replace } = router
  const { notification_settings } = query
  const { isLoading } = useSessionContext()
  const session = useSession()

  React.useEffect(() => {
    // Deleting the "Cloudfront-Expire-Date" cookie to force the server to sign new cookies.
    // This is done to handle cases where the cookies created by cloudfront-signer (Cloudfront-Key-Pair-Id, Cloudfront-Policy, Cloudfront-Signature) become invalid or lost.
    // Since these cookies are HttpOnly and inaccessible on the client side, we can't check their existence directly.
    // By deleting the "Cloudfront-Expire-Date" cookie, we ensure the server sends new, valid cookies to the client, preventing unexpected 403 errors.
    logOnlyInDevelopment(logPrefix, "Deleting Cloudfront-Expire-Date cookie.", "info")
    const domain = APP_URL ? `.${new URL(APP_URL).hostname.split(".").slice(-2).join(".")}` : ""
    const cookieToDelete =
      "Cloudfront-Expire-Date=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=" +
      domain +
      ";"
    logOnlyInDevelopment(logPrefix, "Cookie to delete: " + cookieToDelete, "debug")
    document.cookie = cookieToDelete
  })

  React.useEffect(() => {
    if (isLoading) return
    if (!license && session) {
      const fetchLicense = async () => {
        await getLicense(session).then(res => {
          res.json().then(data => {
            setLicense(data)
          })
        })
      }
      fetchLicense()
    }
  }, [isLoading, license, session])

  if (notification_settings) {
    localStorage.setItem("notification_settings", notification_settings as string)
    replace("/dashboard")
  }

  const reducer = (state: SubjectState, action: Action): SubjectState => {
    switch (action.type) {
      case "TOGGLE_CREATE_ACCOUNT_MODAL":
        return { ...state, showCreateAccountModal: action.payload }
      case "SET_ACCOUNT_INFO":
        return { ...state, accountInfo: action.payload }
      case "SET_SELECTED_EVENTS":
        return { ...state, selectedEvents: action.payload }
      case "SET_ATTENDEES":
        return { ...state, attendees: action.payload }
      default:
        return state
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState)

  const reducerPurchase = (
    state: PurchaseModalState,
    action: PurchaseModalAction
  ): PurchaseModalState => {
    switch (action.type) {
      case "TOGGLE_PURCHASE_MODAL":
        return { ...state, showPurchaseModal: action.payload }
      case "SET_MESSAGE":
        return { ...state, message: action.payload }
      default:
        return state
    }
  }
  const [statePurchase, dispatchPurchase] = useReducer(reducerPurchase, initialStatePurchase)

  const reducerUserInfo = (state: UserInfoState, action: UserInfoAction): UserInfoState => {
    switch (action.type) {
      case "SET_ROLE":
        return { ...state, role: action.payload }
      case "SET_PURPOSE":
        return { ...state, purpose: action.payload }
      default:
        return state
    }
  }
  const [stateUserInfo, dispatchUserInfo] = useReducer(reducerUserInfo, initialStateUserInfo)

  const { pathname } = useRouter()

  React.useEffect(() => {
    const threshold = 1000 * 60 * 5

    if (!session?.user.id) {
      // It is not possible to sign cookies without a session
      logOnlyInDevelopment(logPrefix, "Session is not available. Skipping cookie signing.", "warn")
      return
    }

    checkAndSignCookies(threshold, true)

    const interval = setInterval(() => {
      checkAndSignCookies(threshold, true)
    }, threshold)

    return () => {
      clearInterval(interval)
    }
  }, [pathname, session?.user.id])

  React.useEffect(() => {
    const getUserRecallId = async () => {
      const { data: recall_calendar_id } = await supabasePublicClient.rpc(
        "get_user_recall_calendar_id",
        {
          p_user_uuid: session?.user?.id as string
        }
      )

      if (recall_calendar_id) {
        setCalendarConnected(true)
      } else {
        setCalendarConnected(false)
      }
    }
    if (session?.user.id) {
      getUserRecallId()
    }
  }, [session?.provider_token, session?.user?.email])

  const checkAndSignCookies = async (threshold: number, retryOnFail = false) => {
    try {
      logOnlyInDevelopment(logPrefix, "Checking and signing cookies.", "info")
      logOnlyInDevelopment(logPrefix, "Current route: " + window.location.pathname, "debug")
      logOnlyInDevelopment(
        logPrefix,
        "Session user email: " + JSON.stringify(session?.user?.email),
        "debug"
      )
      const expireDateCookie = getCookieByNameFromDocument("Cloudfront-Expire-Date")
      if (expireDateCookie) {
        const expireDate = parseInt(expireDateCookie?.value || "")
        const difference = expireDate - new Date().getTime()
        if (difference > threshold) {
          logOnlyInDevelopment(
            logPrefix,
            "Cookie is not expired yet. Skipping cookie signing.",
            "debug"
          )
          return
        }
      }
      logOnlyInDevelopment(logPrefix, "Cookie is expired. Signing cookies.", "debug")
      const response = await fetch(`${APP_URL}/api/aws/get-signed-cookies`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${session?.access_token}`
        },
        body: JSON.stringify({ threshold: threshold })
      })
      logOnlyInDevelopment(logPrefix, JSON.stringify("API response: " + response.status), "debug")
      if (!response.ok && retryOnFail) {
        logOnlyInDevelopment(logPrefix, JSON.stringify("API response: " + response.status), "debug")
        logOnlyInDevelopment(logPrefix, "Response not ok. Retrying in 1 second.", "debug")
        // Avoid hitting the server too hard in case of frequent failures.
        setTimeout(() => {
          checkAndSignCookies(threshold, false)
        }, 1000)
      }
    } catch (e) {
      logtail.error(`${logPrefix}${e}`)
    }
  }

  const handleAddFilter = (filter: SortType) => {
    const sortTemp = [...(sort || [])]
    if (sortTemp.includes("Started-Me") && filter === "Started-Other") {
      const filteredSort = sortTemp.filter(e => e !== "Started-Me")
      filteredSort.push(filter)
      setSort(filteredSort)
    } else if (sortTemp.includes("Started-Other") && filter === "Started-Me") {
      const filteredSort = sortTemp.filter(e => e !== "Started-Other")
      filteredSort.push(filter)
      setSort(filteredSort)
    } else if (sortTemp.includes("ASC") && filter === "DESC") {
      const filteredSort = sortTemp.filter(e => e !== "ASC")
      filteredSort.push(filter)
      setSort(filteredSort)
    } else if (sortTemp.includes("DESC") && filter === "ASC") {
      const filteredSort = sortTemp.filter(e => e !== "DESC")
      filteredSort.push(filter)
      setSort(filteredSort)
    } else {
      sortTemp.push(filter)
      setSort(sortTemp)
    }
  }

  const handleRemoveFilter = (filter: SortType) => {
    const sortTemp = [...(sort || [])]
    const filteredSort = sortTemp.filter(e => e !== filter)
    setSort(filteredSort)
  }

  return (
    <DashboardContext.Provider
      value={{
        showDisconnectCalendarModal,
        setShowDisconnectCalendarModal,
        sort,
        setSort,
        handleAddFilter,
        handleRemoveFilter,
        reloadFetchHuudle,
        setReloadFetchHuudle,
        state,
        dispatch,
        huudleData,
        setHuudleData,
        dashboardLoading,
        setDashboardLoading,
        license,
        setLicense,
        statePurchase,
        dispatchPurchase,
        stateUserInfo,
        dispatchUserInfo,
        calendarConnected,
        setCalendarConnected,
        calendarConnectionLoading,
        setCalendarConnectionLoading,
        isFetching,
        setIsFetching,
        page,
        setPage,
        totalHuudleCount,
        setTotalHuudleCount,
        showSubscribeModal,
        setShowSubscribeModal
      }}
    >
      {children}
    </DashboardContext.Provider>
  )
}

export const useDashboard = () => React.useContext(DashboardContext)
