import { Figtree, Poppins, Lexend } from "next/font/google"

export const font = Figtree({
  subsets: ["latin-ext"],
  variable: "--font-family",
  weight: ["400", "500", "600", "700"],
  display: "fallback" // https://github.com/vercel/next.js/issues/42581#issuecomment-1305693300
})

export const lexend = Lexend({
  subsets: ["latin-ext"],
  variable: "--font-family",
  weight: ["400", "500", "600", "700"],
  display: "fallback"
})

export const poppins = Poppins({
  subsets: ["latin-ext"],
  variable: "--font-family",
  weight: ["400", "500", "600", "700"],
  display: "fallback"
})

export const lexendFont = `${lexend.variable} font-sans`

export const poppinsFont = `${poppins.variable} font-sans`

export const fontClassName = `${font.variable} font-sans`
