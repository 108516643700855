import { PostWithProgressType } from "@contexts/huudledetail/index.types"
import { PostType } from "../../../pages/api/entity/HuudleDetailType"

export enum HuudleSummaryStatusEnum {
  Waiting = "waiting",
  Done = "done",
  Summarizing = "summarizing"
}

export enum ContributionStatusEnum {
  Downloading = "downloading",
  Waiting = "waiting",
  Done = "done",
  Transcribing = "transcribing",
  Downloaded = "downloaded"
}

export enum ContributionErrorEnum {
  NOT_READY = "AI Analysis is not ready yet.",
  NOT_FOUND = "AI notes of this Huudle is not generated by AI yet.",
  FAILED = "Something went wrong while generating AI notes."
}

export enum HuudleErrorEnum {
  NOT_FOUND = "No summary found. Please click to generate button for AI summary.",
  FAILED = "Something went wrong",
  NO_POST = "There is no post to summarize"
}

export type HuudleSummaryType = {
  status: HuudleSummaryStatusEnum
  summary?: string

  extended_analysis?: {
    key: "abstract_summary" | "key_points" | "action_items"
    value: string
  }[]
}

export type ContributionSummaryType = {
  post_id: string
  status?: ContributionStatusEnum
  summary?: string
  transcript?: string
  post_type?: "Audio" | "Video" | "Text"
  subtitle?: { start: number; end: number; text: string }[]
  highlight_url?: string
  highlighted_subtitle?: { start: number; end: number; text: string }[]
  tasks?: string[]
  agenda?: string
  meta?: {
    isMeet?: boolean
    original_meet_url?: string
    original_video_duration?: number
  }
}

export type AINotesType = {
  huudle: HuudleSummaryType | null
  contribution: ContributionSummaryType | null
  setHuudle: (data: HuudleSummaryType) => void
  setContribution: (data: ContributionSummaryType) => void
  contributionError: string | null
  contributionLoading: boolean
  huudleError: string | null
  huudleLoading: boolean
  createSummary: (
    posts: PostType[] | PostWithProgressType[] | undefined
  ) => Promise<never[] | NodeJS.Timeout | undefined>
  // huudleSentimentalLoading: boolean
  // huudleSentimentalError: string | null
}

export type SentimentalAnalysisDescriptionType = {
  motives: {
    power: string
    achievement: string
    contact: string
  }
  emotions: string
  preferences: {
    energy: string
    focus: string
    action: string
    attitude: string
  }
  levels: string
}

export type sentimental_analysis = {
  motives: {
    power: number
    achievement: number
    contact: number
  }
  emotions: {
    approach: number
    avoidance: number
  }
  preferences: {
    external: number
    internal: number
    detail: number
    realization: number
    analytical: number
    holistic: number
    path: number
    goal: number
  }
  level1: number
  level2: number
  level3: number
  level4: number
  level5: number
  descriptions: SentimentalAnalysisDescriptionType
  candidate_descriptions: SentimentalAnalysisDescriptionType
}
