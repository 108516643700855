import { useSession, useUser } from "@supabase/auth-helpers-react"
import * as React from "react"
import { initializeFrontendLogtailLogger } from "@utils/logtail"
const useCheckFeatureLicense = (feature_key: string) => {
  const [isValid, setIsValid] = React.useState<boolean>(false)
  const [statusMessage, setErrorMessage] = React.useState<string>("")
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [statusCode, setStatusCode] = React.useState<number>()
  const user = useUser()
  const session = useSession()
  const logtail = initializeFrontendLogtailLogger()

  React.useEffect(() => {
    const checkFeatureLicense = async () => {
      setIsLoading(true)
      try {
        const resp = await fetch("/api/payment/license-management/check-feature-license", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${session?.access_token}`
          },
          body: JSON.stringify({
            feature_key: feature_key,
            user_uuid: user?.id
          })
        })
        if (!resp.ok) {
          throw new Error(`HTTP error! status: ${resp.status}`)
        }
        const data = await resp.json()
        const { isallowed: isAllowed, message, status_code } = data?.licenseStatus?.[0] || {}
        setStatusCode(status_code)
        setIsValid(isAllowed)
        setErrorMessage(message)
      } catch (error) {
        await logtail.error(`Error checking feature license:${(error as Error).message}`)
        setErrorMessage("An error occurred while checking the feature license.")
      } finally {
        setIsLoading(false)
      }
    }
    if (user?.id && feature_key) {
      checkFeatureLicense()
    }
  }, [user?.id, feature_key])
  return { isValid, statusMessage, isLoading, statusCode }
}

export { useCheckFeatureLicense }
