import * as React from "react"
import { convertSecondsToHMS } from "@utils/covert-seconds-to-hms"
import { type HuudleDetailType } from "../../../pages/api/entity/HuudleDetailType"
import {
  type ProviderType,
  type HuudleDetailContextType,
  type MediaType,
  type PostWithProgressType,
  type CurrentMediaType,
  NavigationState
} from "./index.types"
import { ParticipantsListType } from "api/get-participants"

export const HuudleDetailContext = React.createContext<HuudleDetailContextType>({
  currentMedia: undefined,
  details: undefined,
  loading: true,
  media: {
    audio: null,
    video: null,
    video_meta: null,
    comment: ""
  },
  postsWithProgress: undefined,
  provider: "",
  setCurrentMedia: () => null,
  setDetails: () => null,
  setLoading: () => null,
  setMedia: () => null,
  setPostsWithProgress: () => undefined,
  setProvider: () => null,
  totalDurationString: "",
  referenceUserButton: null,
  setReferenceUserButton: () => null,
  setParticipants: () => null,
  participants: [],
  isAnonUser: false,
  setIsAnonUser: () => null,
  backgroundColor: "#ffffff",
  setBackgroundColor: () => null,
  signupModal: false,
  setSignupModal: () => null,
  runSubmit: false,
  setRunSubmit: () => null,
  isNameModal: false,
  setIsNameModalOpen: () => null,
  isSideNavigationOpen: false,
  setIsSideNavigationOpen: () => null,
  navigationState: "huudle",
  setNavigationState: () => null,
  setIsProjectDetailsOpen: () => null,
  isProjectDetailsOpen: false,
  setIsReferFriendModalOpen: () => null,
  isReferFriendModalOpen: false,
  showAsanaProjectsModal: false,
  setShowAsanaProjectsModal: () => null,
  showAsanaTaskSendsModal: false,
  setShowAsanaTaskSendsModal: () => null,
  openIndex: -1,
  setOpenIndex: () => null
})

export const HuudleDetailProvider = ({ children }: React.PropsWithChildren) => {
  const [loading, setLoading] = React.useState<boolean>(true)
  const [details, setDetails] = React.useState<HuudleDetailType>()
  const [provider, setProvider] = React.useState<ProviderType>("")
  const [signupModal, setSignupModal] = React.useState(false)
  const [runSubmit, setRunSubmit] = React.useState(false)
  const [isNameModal, setIsNameModalOpen] = React.useState<boolean>(false)
  const [isSideNavigationOpen, setIsSideNavigationOpen] = React.useState<boolean>(false)
  const [navigationState, setNavigationState] = React.useState<NavigationState>("huudle")
  const [isProjectDetailsOpen, setIsProjectDetailsOpen] = React.useState(false)
  const [isReferFriendModalOpen, setIsReferFriendModalOpen] = React.useState(false)
  const [showAsanaProjectsModal, setShowAsanaProjectsModal] = React.useState(false)
  const [showAsanaTaskSendsModal, setShowAsanaTaskSendsModal] = React.useState<
    | {
        id: string
        content: string
      }
    | boolean
    | boolean
  >(false)
  const [openIndex, setOpenIndex] = React.useState(-1)

  const [media, setMedia] = React.useState<MediaType>({
    audio: null,
    video: null,
    video_meta: null,
    comment: ""
  })
  const [currentMedia, setCurrentMedia] = React.useState<CurrentMediaType>()

  const [postsWithProgress, setPostsWithProgress] = React.useState<
    PostWithProgressType[] | undefined
  >()

  const [referenceUserButton, setReferenceUserButton] = React.useState<
    HTMLButtonElement | HTMLDivElement | null
  >(null)

  const [backgroundColor, setBackgroundColor] = React.useState<string>(
    details?.huudle?.meta?.space || "#ffffff"
  )

  React.useEffect(() => {
    if (details?.huudle?.meta?.space) {
      setBackgroundColor(details.huudle.meta?.space)
    }
  }, [details?.huudle?.meta?.space])

  const totalDurationFloat = postsWithProgress?.reduce(
    (acc, item) => (item.meta?.duration ? acc + item.meta?.duration : acc),
    0
  )

  const totalDuration = Math.floor(totalDurationFloat || 0)

  const { hours, minutes, seconds } = convertSecondsToHMS(totalDuration || 0)
  const totalDurationString = `${hours ? `${hours}:` : ""}${minutes ? `${minutes}:` : "00:"}${
    seconds ? `${seconds > 9 ? seconds : `0${seconds}`}` : "00"
  }`

  const [participants, setParticipants] = React.useState<ParticipantsListType>([])
  const [isAnonUser, setIsAnonUser] = React.useState<boolean>(false)

  return (
    <HuudleDetailContext.Provider
      value={{
        isProjectDetailsOpen,
        setIsProjectDetailsOpen,
        loading,
        setLoading,
        provider,
        setProvider,
        details,
        setDetails,
        media,
        setMedia,
        currentMedia,
        setCurrentMedia,
        postsWithProgress,
        setPostsWithProgress,
        totalDurationString,
        referenceUserButton,
        setReferenceUserButton,
        setParticipants,
        participants,
        isAnonUser,
        setIsAnonUser,
        backgroundColor,
        setBackgroundColor,
        setSignupModal,
        signupModal,
        runSubmit,
        setRunSubmit,
        isNameModal,
        setIsNameModalOpen,
        isSideNavigationOpen,
        setIsSideNavigationOpen,
        navigationState,
        setNavigationState,
        isReferFriendModalOpen,
        setIsReferFriendModalOpen,
        showAsanaProjectsModal,
        setShowAsanaProjectsModal,
        showAsanaTaskSendsModal,
        setShowAsanaTaskSendsModal,
        openIndex,
        setOpenIndex
      }}
    >
      {children}
    </HuudleDetailContext.Provider>
  )
}

export const useHuudleDetail = () => React.useContext(HuudleDetailContext)
