import * as React from "react"
import { type ContextType } from "./index.types"

export const TimelineContext = React.createContext<ContextType>({
  isPlaying: false,
  setIsPlaying: () => null,
  speed: 1,
  setSpeed: () => null,
  volume: 1,
  setVolume: () => null
})

export const TimelineProvider = ({ children }: React.PropsWithChildren) => {
  const [isPlaying, setIsPlaying] = React.useState(false)
  const [speed, setSpeed] = React.useState<number>(1)
  const [volume, setVolume] = React.useState<number>(1)

  React.useEffect(() => {
    const speed = localStorage.getItem("speed")
    if (speed) {
      setSpeed(Number(speed))
    }
  }, [])

  return (
    <TimelineContext.Provider
      value={{ isPlaying, setIsPlaying, speed, setSpeed, volume, setVolume }}
    >
      {children}
    </TimelineContext.Provider>
  )
}

export const useTimeline = () => React.useContext(TimelineContext)
export * from "./index.types"
