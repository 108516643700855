import * as React from "react"
import type ContextType from "./index.types"

export const LoadingContext = React.createContext<ContextType>({
  isLoading: false,
  setIsLoading: () => null
})

export const LoadingProvider = ({ children }: React.PropsWithChildren) => {
  const [isLoading, setIsLoading] = React.useState(false)

  return (
    <LoadingContext.Provider value={{ isLoading, setIsLoading }}>
      {children}
    </LoadingContext.Provider>
  )
}

export const useLoading = () => React.useContext(LoadingContext)
export * from "./index.types"
