import React, { createContext, useEffect, useState, FC, PropsWithChildren } from "react"
import { useSession } from "@supabase/auth-helpers-react"

import { type ProfileType, getProfile } from "@service/get-profile"
import { useQuerify } from "@hooks/use-querify"
import { ProfileContextType } from "./index.types"

export const ProfileContext = createContext<ProfileContextType | undefined>(undefined)

export const ProfileContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const session = useSession()
  const [profile, setProfile] = useState<ProfileType | undefined>(undefined)

  const {
    data: profileData,
    isLoading,
    error,
    run
  } = useQuerify(getProfile, {
    key: "profile",
    variables: [session?.user?.id as string]
  })

  useEffect(() => {
    if (profileData) {
      const email = session?.user.email || ""
      const isAnon = email.includes("anonymous") && email.includes("@huudle.io")
      if (isAnon) {
        const profileDataWithIsAnon = { ...profileData, isAnon }
        setProfile(profileDataWithIsAnon)
      } else {
        setProfile(profileData)
      }
    }
  }, [profileData, session?.user.email])

  return (
    <ProfileContext.Provider
      value={{
        profile,
        isLoading,
        error,
        run
      }}
    >
      {children}
    </ProfileContext.Provider>
  )
}
