import { supabasePublicClient } from "@lib/supabase"

export type RawUserMetaData = {
  [key: string]: number | string | boolean
}

export type ProfileType = {
  avatar_url: string | null
  contributed: boolean
  name: string | undefined
  username: string
  raw_user_meta_data: {
    [key: string]: string | RawUserMetaData
  }
  isAnon?: boolean
}

export const getProfile = async (userId: string): Promise<ProfileType | null> => {
  if (!userId) {
    return null
  }

  const { data, error } = await supabasePublicClient
    .from("profiles")
    .select("avatar_url, contributed, display_name, username,raw_user_meta_data")
    .eq("id", userId)
    .limit(1)
    .single()

  if (data) {
    return {
      contributed: data.contributed as boolean,
      avatar_url: data.avatar_url as string | null,
      name: data.display_name as string | undefined,
      username: data.username as string,
      raw_user_meta_data: data.raw_user_meta_data as {
        [key: string]: string | RawUserMetaData
      }
    }
  } else if (error) {
    return null
  }

  return null
}
