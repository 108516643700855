import { Logtail } from "@logtail/node"

export const initializeBackendLogtailLogger = () => {
  const logtailToken =
    process.env.LOGTAIL_SOURCE_TOKEN_TEST !== undefined && process.env.NEXT_PUBLIC_ENV === "PROD"
      ? process.env.LOGTAIL_SOURCE_TOKEN
      : process.env.LOGTAIL_SOURCE_TOKEN_TEST
  const logtail = new Logtail(logtailToken as string)
  return logtail
}

export const initializeFrontendLogtailLogger = () => {
  const logtailToken =
    process.env.NEXT_PUBLIC_LOGTAIL_FRONTEND_SOURCE_TOKEN_TEST !== undefined &&
    process.env.NEXT_PUBLIC_ENV === "PROD"
      ? process.env.NEXT_PUBLIC_LOGTAIL_FRONTEND_SOURCE_TOKEN
      : process.env.NEXT_PUBLIC_LOGTAIL_FRONTEND_SOURCE_TOKEN_TEST
  const logtail = new Logtail(logtailToken as string)
  return logtail
}
